<template>
  <div>
    <b-row>
      <b-col class="col-12 col-md-6">
        <b-card no-body>
          <b-card-header>
            <b-card-title :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'">
              {{ $t('accountManagement.myData') }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-list-group flush>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="overflow-hidden">
                  <span class="font-weight-bold">
                    {{ $t('accountManagement.email') }}
                  </span>
                  <br>
                  <span class="font-small-3">
                    {{ user.email }}
                  </span>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="overflow-hidden">
                  <span class="font-weight-bold">
                    {{ $t('accountManagement.firstName') }}
                  </span>
                  <br>
                  <span class="font-small-3">
                    {{ user.first_name }}
                  </span>
                </div>
                <b-button
                    class="ml-1 p-50"
                    pill
                    size="sm"
                    :variant="$store.state.appConfig.layout.skin == 'dark' ? 'flat-secondary' : 'flat-primary'"
                    @click="popUpEditName">
                  <feather-icon
                      icon="Edit2Icon"
                      size="20"/>
                </b-button>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="overflow-hidden">
                  <span class="font-weight-bold">
                    {{ $t('accountManagement.lastName') }}
                  </span>
                  <br>
                  <span class="font-small-3">
                    {{ user.last_name }}
                  </span>
                </div>
                <b-button
                    class="ml-1 p-50"
                    pill
                    size="sm"
                    :variant="$store.state.appConfig.layout.skin == 'dark' ? 'flat-secondary' : 'flat-primary'"
                    @click="popUpEditName">
                  <feather-icon
                      icon="Edit2Icon"
                      size="20"/>
                </b-button>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between">
                <div class="font-weight-bold overflow-hidden">
                  <span class="font-weight-bold">
                    {{ $t('accountManagement.password') }}
                  </span>
                  <br>
                  <span class="font-small-3">
                    **********
                  </span>
                </div>
                <b-button
                    class="ml-1 p-50"
                    pill
                    size="sm"
                    :variant="$store.state.appConfig.layout.skin == 'dark' ? 'flat-secondary' : 'flat-primary'"
                    @click="changePassword">
                  <feather-icon
                      icon="Edit2Icon"
                      size="20"/>
                </b-button>
              </b-list-group-item>
              <b-list-group-item
                  class="d-flex justify-content-between">
                <div class="overflow-hidden">
                  <span class="font-weight-bold">
                    {{ $t('accountManagement.category') }}
                  </span>
                  <br>
                  <span class="font-small-3">
                    {{ userCategoryName }}
                  </span>
                </div>
                <b-button
                    class="ml-1 p-50"
                    pill
                    size="sm"
                    :variant="$store.state.appConfig.layout.skin == 'dark' ? 'flat-secondary' : 'flat-primary'"
                    @click="popUpEditCategory">
                  <feather-icon
                      icon="Edit2Icon"
                      size="20"/>
                </b-button>
              </b-list-group-item>
              <b-list-group-item
                  class="d-flex justify-content-between">
                <div class="overflow-hidden">
                  <span class="font-weight-bold">
                    {{ $t('accountManagement.languageChoice') }}
                  </span>
                  <br>
                  <span class="font-small-3">
                    {{ languageOption.filter(e => e.value === $i18n.locale)[0].text }}
                  </span>
                </div>
                <b-button
                    class="ml-1 p-50"
                    pill
                    size="sm"
                    :variant="$store.state.appConfig.layout.skin == 'dark' ? 'flat-secondary' : 'flat-primary'"
                    @click="popuUpEditLanguage">
                  <feather-icon
                      icon="Edit2Icon"
                      size="20"/>
                </b-button>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-6">
        <!-- Delete Account Card -->
        <b-card no-body>
          <b-card-header>
            <b-card-title :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'">
              {{ $t('accountManagement.deleteAccount') }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-card-text class="p-1 text-justify">
              {{ $t('accountManagement.deleteAccountWarning') }}
            </b-card-text>
            <b-button
                block
                variant="outline-danger"
                @click="deleteAccountPrompt=true">
              {{ $t('accountManagement.confirmDeletion') }}
            </b-button>
          </b-card-body>
        </b-card>

        <!-- Application Appearance Card -->
        <b-card no-body>
          <b-card-header>
            <b-card-title :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'">
              {{ $t('accountManagement.appAppearance') }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form-group class="flex justify-content-md-around">
              <div class="d-flex justify-content-around">
                <b-form-radio v-model="skin" value="light" @change="changeColor">
                  {{ $t('accountManagement.lightTheme') }}
                </b-form-radio>
                <b-form-radio v-model="skin" value="dark" @change="changeColor">
                  {{ $t('accountManagement.darkTheme') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-card-title :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'">
              {{ $t('accountManagement.myRights') }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-button
                class="m-1"
                :variant="$store.state.appConfig.layout.skin == 'dark' ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'general-conditions' }">
              {{ $t('accountManagement.termsOfUse') }}
            </b-button>
            <b-button
                class="m-1"
                :variant="$store.state.appConfig.layout.skin == 'dark' ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'gdpr-charter' }">
              {{ $t('accountManagement.dataProtectionCharter') }}
            </b-button>
            <b-button
                class="m-1"
                :variant="$store.state.appConfig.layout.skin == 'dark' ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'cookie-policy' }">
              {{ $t('accountManagement.cookiePolicy') }}
            </b-button>

            <b-card-text class="m-1 text-justify">
              {{ $t('accountManagement.dataOwnershipText') }}
              <a class="underline font-weight-bold"
                 href="mailto:contact@jaya-garden.fr"
                 :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'">
                contact&#64;jaya-garden&#46;fr
              </a>
            </b-card-text>

            <b-list-group flush class="mt-1">
              <b-list-group-item
                  class="d-flex justify-content-start align-items-center"
                  v-for="(rightRGPD,id) in rightsRGPD"
                  :key="id">
                <b-button
                    class="mr-1 p-50"
                    pill
                    size="sm"
                    :variant="$store.state.appConfig.layout.skin == 'dark' ? 'flat-secondary' : 'flat-primary'"
                    :href="rightRGPD.url">
                  <feather-icon
                      :icon="rightRGPD.featherIcon"
                      size="24"/>
                </b-button>
                <div>
                <span class="font-weight-bold">
                  {{ rightRGPD.title }}
                </span>
                  <br>
                  <span class="font-small-3">
                  {{ rightRGPD.description }}
                </span>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <validation-observer v-slot="{invalid}">
      <b-modal
          v-model="editNameIsPrompt"
          :ok-disabled="invalid"
          :cancel-title="$t('accountManagement.cancelButton')"
          :ok-title="$t('accountManagement.validateButton')"
          :title="$t('accountManagement.editNameTitle')"
          variant="secondary"
          @ok="editName">
        <b-form-group :label="$t('accountManagement.firstNameLabel')">
          <validation-provider v-slot="{errors}" name="Prénom" rules="max:30">
            <b-form-input v-model="editingFirstName" :placeholder="$t('accountManagement.firstNameLabel')"/>
            <p class="text-danger">
              {{ errors[0] }}
            </p>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('accountManagement.lastNameLabel')">
          <validation-provider v-slot="{errors}" name="Nom" rules="max:150">
            <b-form-input v-model="editingLastName" :placeholder="$t('accountManagement.lastNameLabel')"/>
            <p class="text-danger">
              {{ errors[0] }}
            </p>
          </validation-provider>
        </b-form-group>

        <p v-if="!validName" class="text-danger">
          {{ $t('accountManagement.nameTooLongOrInvalid') }}
        </p>
      </b-modal>
    </validation-observer>

    <b-modal
        v-model="editCategoryIsPrompt"
        :cancel-title="$t('accountManagement.cancelButton')"
        :ok-title="$t('accountManagement.validateButton')"
        :title="$t('accountManagement.editCategoryTitle')"
        variant="secondary"
        @ok="editCategory">
      <b-form-group :label="$t('accountManagement.categoryLabel')">
        <b-form-select
            v-model="editingCategory"
            :options="getCategories"/>
      </b-form-group>
    </b-modal>
    <b-modal
        v-model="editLanguageIsPrompt"
        :cancel-title="$t('accountManagement.cancelButton')"
        :ok-title="$t('accountManagement.validateButton')"
        :title="$t('accountManagement.editLanguageTitle')"
        variant="secondary"
        @ok="editLanguage">
      <b-form-group :label="$t('accountManagement.languageLabel')">
        <b-form-select
            v-model="language"
            :options="languageOption"/>
      </b-form-group>
    </b-modal>
    <b-modal
        v-model="deleteAccountPrompt"
        :ok-disabled="!passwordExists"
        :cancel-title="$t('accountManagement.cancelButton')"
        centered
        :ok-title="$t('accountManagement.deleteButton')"
        ok-variant="danger"
        :title="$t('accountManagement.deleteAccountTitle')"
        cancel-variant="warning"
        @ok="deleteAccount">

      <b-alert show variant="danger" class="p-1">
        {{ $t('accountManagement.deleteAccountConfirmation') }}
      </b-alert>

      <b-form-group :label="$t('accountManagement.passwordInputLabel')">
        <validation-provider v-slot="{errors}" rules="required">
          <b-input-group class="flex-nowrap">
            <b-input-group-prepend is-text>
              <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"/>
            </b-input-group-prepend>
            <b-form-input
                ref="password"
                v-model="password"
                :type="passwordFieldType"
                class="w-full mt-6"
                :label-placeholder="$t('accountManagement.passwordPlaceholder')"
                name="password"
                :placeholder="$t('accountManagement.passwordPlaceholder')"/>
          </b-input-group>
          <p class="text-danger">{{ errors[0] }}</p>
          <p v-if="passwordError" class="text-danger">
            {{ $t('accountManagement.incorrectPassword') }}
          </p>
        </validation-provider>
      </b-form-group>

      <div class="cursor-pointer text-info" @click="forgotPassword">
        {{ $t('accountManagement.forgotPassword') }}
      </div>
    </b-modal>
  </div>
</template>

<script type="text/javascript">
import 'vue-simple-suggest/dist/styles.css'
import {mapState} from "vuex"
import {
  BAlert,
  BButton,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
} from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {max, required} from "vee-validate/dist/rules";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'

extend('required', {...required, message: 'Ce champ est requis'});
extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});

export default {
  name: 'AccountSettings',
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormRadio,
    BInputGroupPrepend,
    BInputGroup,
    BAlert,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      editNameIsPrompt: false,
      editCategoryIsPrompt: false,
      editLanguageIsPrompt: false,
      validName: true,
      settingsIsPopUp: false,
      deleteAccountPrompt: false,
      editingFirstName: '',
      editingLastName: '',
      editingCategory: null,
      password: '',
      passwordError: false,
      keepColor: [],
      activeColor: null,
      language: null,
      skin: '',
      optionCategory: [{value: null, text: 'Quelle est votre catégorie ?', disabled: true}]
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
    }),
    passwordExists() {
      return this.password !== ''
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    userCategoryName() {
      return this.$store.state.cfp.quizTypeName.find(el => el.type === this.user.category).name[this.user.language]
    },
    getCategories() {
      return this.optionCategory.filter(e => e.language === this.user.language)
    },
    rightsRGPD() {
      return [
        {
          title: this.$t('accountManagement.GDPRRights.infoRight.title'),
          featherIcon: "InfoIcon",
          url: "https://www.cnil.fr/fr/le-droit-detre-informe-sur-lutilisation-de-vos-donnees-0",
          description: this.$t('accountManagement.GDPRRights.infoRight.description')
        },
        {
          title: this.$t('accountManagement.GDPRRights.oppositionRight.title'),
          featherIcon: "AlertTriangleIcon",
          url: "https://www.cnil.fr/fr/le-droit-dopposition-refuser-lutilisation-de-vos-donnees",
          description: this.$t('accountManagement.GDPRRights.oppositionRight.description')
        },
        {
          title: this.$t('accountManagement.GDPRRights.verificationRight.title'),
          featherIcon: "CheckSquareIcon",
          url: "https://www.cnil.fr/fr/le-droit-dacces-connaitre-les-donnees-quun-organisme-detient-sur-vous",
          description: this.$t('accountManagement.GDPRRights.verificationRight.description')
        },
        {
          title: this.$t('accountManagement.GDPRRights.rectificationRight.title'),
          featherIcon: "EditIcon",
          url: "https://www.cnil.fr/fr/le-droit-de-rectification-corriger-vos-informations",
          description: this.$t('accountManagement.GDPRRights.rectificationRight.description')
        },
        {
          title: this.$t('accountManagement.GDPRRights.deletionRight.title'),
          featherIcon: "TrashIcon",
          url: "https://www.cnil.fr/fr/le-droit-leffacement-supprimer-vos-donnees-en-ligne",
          description: this.$t('accountManagement.GDPRRights.deletionRight.description')
        },
        {
          title: this.$t('accountManagement.GDPRRights.dataPortabilityRight.title'),
          featherIcon: "DownloadIcon",
          url: "https://www.cnil.fr/fr/le-droit-la-portabilite-obtenir-et-reutiliser-une-copie-de-vos-donnees",
          description: this.$t('accountManagement.GDPRRights.dataPortabilityRight.description')
        },
        {
          title: this.$t('accountManagement.GDPRRights.dataFreezeRight.title'),
          featherIcon: "XIcon",
          url: "https://www.cnil.fr/fr/le-droit-la-limitation-du-traitement-geler-lutilisation-de-vos-donnees",
          description: this.$t('accountManagement.GDPRRights.dataFreezeRight.description')
        },
        {
          title: this.$t('accountManagement.GDPRRights.otherRights.title'),
          featherIcon: "PlusCircleIcon",
          url: "https://www.cnil.fr/les-droits-pour-maitriser-vos-donnees-personnelles",
          description: this.$t('accountManagement.GDPRRights.otherRights.description')
        }
      ]
    },
    languageOption()  {
      return [
        {value: null, text: "Sélectionnez une langue", disabled: true},
        {
          value: "fr",
          text: "Français"
        },
        {
          value: "en", text: "English"
        },
        {value: "es", text: "Español"}
      ]
    }
  },
  mounted() {
    this.skin = this.$store.state.appConfig.layout.skin
    this.$store.dispatch("cfp/fetchQuizType", {year: new Date().getFullYear()})
        .then(() => {
          this.$store.state.cfp.quizType.forEach((e) => {
            this.optionCategory.push({
              value: e.type,
              text: this.$store.state.cfp.quizTypeName.find(el => el.type === e.type).name[e.language],
              disabled: false,
              language: e.language
            })
          })
        })
        .catch(() => {
          this.$notify(
              {
                title: this.$t('Error'),
                text: this.$t('quizTypeCouldNotBeFetched'),
                time: 3000,
                color: "danger"
              }
          )
        })
  },
  methods: {
    popUpEditName() {
      this.validName = true
      this.editingFirstName = this.user.first_name
      this.editingLastName = this.user.last_name
      this.editNameIsPrompt = true
    },
    popUpEditCategory() {
      this.editingCategory = this.user.category
      this.editCategoryIsPrompt = true
    },
    popuUpEditLanguage() {
      this.language = this.user.language
      this.editLanguageIsPrompt = true
    },
    editName() {
      this.$store.dispatch('account/updateProfile', {
        first_name: this.editingFirstName,
        last_name: this.editingLastName
      })
          .then(() => {
            this.$loading(false)
            this.editNameIsPrompt = false
            this.$notify({
              time: 4000,
              color: "secondary",
              title: this.$t('accountManagement.accountEdited.successTitle'),
              text: this.$t('accountManagement.accountEdited.successText')
            })
          })
          .catch(() => {
            this.$loading(false)
            this.editNameIsPrompt = false
            this.$notify({
              time: 4000,
              color: "danger",
              title: this.$t('Error'),
              text: this.$t('accountManagement.accountEdited.errorText')
            })
          })
    },
    editCategory() {
      this.$store.dispatch('account/updateProfile', {
        category: this.editingCategory,
      })
          .then(() => {
            this.$loading(false)
            this.editCategoryIsPrompt = false
            this.$notify({
              time: 4000,
              color: "secondary",
              title: this.$t('accountManagement.accountEdited.successCategoryTitle'),
              text: this.$t('accountManagement.accountEdited.successCategoryText')
            })
          })
          .catch(() => {
            this.$loading(false)
            this.editCategoryIsPrompt = false
            this.$notify({
              time: 4000,
              color: "danger",
              title: this.$t('Error'),
              text: this.$t('accountManagement.accountEdited.errorText')
            })
          })
    },
    editLanguage() {
      this.$store.dispatch('account/updateProfile', {
        language: this.language,
      })
          .then(() => {
            this.$loading(false)
            this.editCategoryIsPrompt = false
            this.$notify({
              time: 4000,
              color: "secondary",
              title: this.$t('accountManagement.accountEdited.successCategoryTitle'),
              text: this.$t('accountManagement.accountEdited.successCategoryText')
            })
          })
          .catch(() => {
            this.$loading(false)
            this.editCategoryIsPrompt = false
            this.$notify({
              time: 4000,
              color: "danger",
              title: this.$t('Error'),
              text: this.$t('accountManagement.accountEdited.errorText')
            })
          })
    },
    logout() {
      this.$loading(true);
      this.settingsIsPopUp = false;
      this.$logout()
          .then(() => {
            this.$loading(false);
            this.$notify({
              time: 4000,
              color: "secondary",
              title: this.$t('accountManagement.loggedOut'),
              text: this.$t('accountManagement.loggedOutText')
            });
            this.$router.push({name: 'login'});
          })
          .catch(() => {
            this.$loading(false);
            this.$notify({
              time: 4000,
              color: "danger",
              title: this.$t('Error'),
              text: this.$t('accountManagement.logoutFailed')
            });
          });
    },
    changePassword() {
      this.settingsIsPopUp = false
      this.$router.push({name: "change-password"});
    },
    deleteAccount() {
      this.$loading(true);
      let payload = {password: this.password};
      this.$store.dispatch('account/deleteAccount', payload)
          .then(() => {
            this.deleteAccountPrompt = false;
            this.settingsIsPopUp = false;
            this.$notify({
              title: this.$t('accountManagement.accountDeleted'),
              text: this.$t('accountManagement.accountDeletedText'),
              color: "warning",
              time: 4000
            });
            this.$loading(false);
            this.$router.push({name: "login"});
          })
          .catch(error => {
            this.$loading(false);
            this.deleteAccountPrompt = true;
            this.passwordError = false;
            if (error === "passwordError") {
              this.passwordError = true;
              this.$notify({
                title: this.$t('Error'),
                text: this.$t('accountManagement.passwordInvalid'),
                color: "danger",
                time: 4000
              });
            } else {
              this.$notify({
                title: this.$t('Error'),
                text: this.$t('accountManagement.accountDeletionFailed'),
                color: "danger",
                time: 4000
              });
            }
          });
    },
    forgotPassword() {
      this.deleteAccountPrompt = false
      this.settingsIsPopUp = false
      this.$router.push({name: "forgot-password"})
    },
    changeColor() {
      this.$store.commit('appConfig/UPDATE_SKIN', this.skin)
    }
  }
}
</script>
